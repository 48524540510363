import request from "./request.js";
import qs from "qs"; 

if (process.env.NODE_ENV === "development") {
    var baseUrl = 'https://aiapi.shatakj.com'//开发环境  
} else {  
    var baseUrl = 'https://zntapi.shatakj.com'//生产环境
}
// const baseUrl = 'https://aiapi.shatakj.com'
// const baseUrl = 'https://aiapi.shatakj.com'

// 应用初始化 
export function initialize() {
    return request({
        url: baseUrl + "/api/web/init-config",
        method: "get",
    });
}

// 查询数字人单价

export function pointSpriceList() {
    return request({
        url: baseUrl + "/api/points/price-list",
        method: "get",
    });
}

//登录
export function authCodeLogin(data) {
    return request({
        url: baseUrl + "/api/token",
        method: "post",
        data: data
    });
}

//获取用户数据
export function getUserInfo() {
    return request({
        url: baseUrl + "/api/user",
        method: "get",
    });
}
// 修改用户信息

export function editUserInfor(data) {
    return request({
        url: baseUrl + "/api/user",
        method: "put",
        data: data
    });
}

// 查询积分使用记录
export function getIntegralRecord(params) {
    return request({
        url: baseUrl + "/api/points/page",
        method: "get",
        params: params
    });
}


// 注册
export function sigin(data) {
    return request({
        url: baseUrl + "/api/register",
        method: "post",
        data: data
    });
}
// 公共声音模型
export function getCommonsoundModel(params) {
    return request({
        url: baseUrl + "/api/dp/sound/public",
        method: "get",
        params: params
    });
}
// 我的声音模型
export function getMysoundModel(params) {
    return request({
        url: baseUrl + "/api/dp/sound/page",
        method: "get",
        params: params
    });
}
// 删除声音模型
export function deleteSoundModel(data) {
    return request({
        url: baseUrl + "/api/dp/sound",
        method: "delete",
        data: data
    });
}
// 可用克隆次数
export function getAvailable() {
    return request({
        url: baseUrl + "/api/dp/sound/available",
        method: "get",
    });
}
// 获取公共标签
export function getTagList() {
    return request({
        url: baseUrl + "/api/dp/tag/public",
        method: "get",
    });
}

// 合成音频试听
export function syntheticAudition(data) {
    return request({
        url: baseUrl + "/api/dp/sound/tryout",
        method: "post",
        data: data
    });
}
// 刷新试听音频
export function refreshAudition(data) {
    return request({
        url: baseUrl + "/api/dp/sound/refresh",
        method: "post",
        data: data
    });
}
// 确认声音克隆
export function confirmAudio(data) {
    return request({
        url: baseUrl + "/api/dp/sound/confirm",
        method: "post",
        data: data
    });
}
// 修改音频信息
export function modifyingAudioInfor(data) {
    return request({
        url: baseUrl + "/api/dp/sound",
        method: "put",
        data: data
    });
}
// 获取我的形象
export function getMyModelList(params) {
    return request({
        url: baseUrl + "/api/dp/model/page",
        method: "get",
        params: params
    });
}
// 修改我的形象
export function editMyMode(data) {
    return request({
        url: baseUrl + "/api/dp/model",
        method: "put",
        data: data
    });
}
// 刷新我的形象
export function refreshMyMode(data) {
    return request({
        url: baseUrl + "/api/dp/model/refresh",
        method: "post",
        data: data
    });
}
// 删除我的形象
export function deleteMyMode(data) {
    return request({
        url: baseUrl + "/api/dp/model",
        method: "delete",
        data: data
    });
}
// 获取公共形象
export function getComModelList(params) {
    return request({
        url: baseUrl + "/api/dp/model/public/list",
        method: "get",
        params: params
    });
}
// 搜索我的形象
export function searchModelList(params) {
    return request({
        url: baseUrl + "/api/dp/model",
        method: "get",
        params: params
    });
}
// 获取我的形象作品
export function getMyImageList(params) {
    return request({
        url: baseUrl + "/api/dp/video/page",
        method: "get",
        params: params
    });
}

// 创建我的作品
export function createMyWork(data) {
    return request({
        url: baseUrl + "/api/dp/video",
        method: "post",
        data: data
    });
}


// 克隆形象
export function cloneImage(data) {
    return request({
        url: baseUrl + "/api/dp/model",
        method: "post",
        data: data
    });
}
// 文本合成音频
export function textToAudio(data) {
    return request({
        url: baseUrl + "/api/dp/audio",
        method: "post",
        data: data
    });
}
// 获取合成音频列表
export function getSyntheticAudioList(params) {
    return request({
        url: baseUrl + "/api/dp/audio/page",
        method: "get",
        params: params
    });
}
// 刷新单个文本合成的音频
export function refreshSyntheticAudio(data) {
    return request({
        url: baseUrl + "/api/dp/audio/refresh",
        method: "post",
        data: data
    });
}
// 删除合成音频
export function deleteSyntheticAudio(data) {
    return request({
        url: baseUrl + "/api/dp/audio",
        method: "delete",
        data: data
    });
}

// 我的作品单个刷新
export function refreshMyWork(data) {
    return request({
        url: baseUrl + "/api/dp/video/refresh",
        method: "post",
        data: data
    });
}
// 我的作品单个删除
export function deleteMyWorkVideo(data) {
    return request({
        url: baseUrl + "/api/dp/video",
        method: "delete",
        data: data
    });
}
// 我的作品单个修改
export function editMyWorkVideo(data) {
    return request({
        url: baseUrl + "/api/dp/video",
        method: "put",
        data: data
    });
}

// AI创作
export function aiCreate(data) {
    return request({
        url: baseUrl + "/api/ai/writing",
        method: "post",
        data: data
    });
}

// 资料库列表
export function getImagedataBank(params) {
    return request({
        url: baseUrl + "/api/faFile/getList",
        method: "get",
        params: params
    });
}

// 资料库删除
export function dataBankDetele(data) {
    return request({
        url: baseUrl + "/api/faFile/delete",
        method: "delete",
        data: data
    });
}

// 资料库上传
export function uploadDataBank(data) {
    return request({
        url: baseUrl + "/api/faFile/put",
        method: "post",
        data: data
    });
}




//params是添加到url的请求字符串中的，用于get请求。
//data是添加到请求体（body）中的， 用于post、put、delete请求。